<script>
import api from '@/services/api';
import draggable from 'vuedraggable';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    sortItems,
    updateObjectToNew
} from '@/util/helper';
import vSelect from 'vue-select';
import { isArray, isObject } from '@/util/inspect.util';
import pasteImageFileAgent from '@/mixins/pasteImageFileAgent';
import flatPickr from 'vue-flatpickr-component';
import TournamentsSelect from '@/views/tournament/tournament-tasks/TournamentsSelect.vue';
import TasksSelect from '@/views/tournament/tournament-tasks/TasksSelect.vue';
import { checkLocales } from '@/util/lang';
import { addMonths, formatDateToYMD } from '@/util/date/calendar.util';

export default {
    name: 'AppTournamentTasks',
    components: {
        TasksSelect,
        TournamentsSelect,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        draggable,
        vSelect,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        flatPickr
    },
    mixins: [CRUDPageMixin, pasteImageFileAgent],
    data() {
        return {
            items: [],
            tournamentOptions: null,
            taskOptions: null,
            tournament: {},
            dateTasks: new Date(),
            dataForModal: {
                task: '',
                tournament: {},
                start_date: null,
                // end_date: null,
                qty: null,
                position: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 30
            },
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            uploadImage: null,
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                // this.getTournamentTasks();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },
        dateTasks() {
            this.replaceRouter({
                ...this.query,
                page: 1
            });
            this.getTournamentTasks();
        },

        uploadImage(image) {
            this.dataForModal.images = [];
            if (isArray(image)) {
                this.dataForModal.images = image;
            } else if (isObject(image)) {
                this.dataForModal.images.push(image);
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'task',
                    label: this.$t('tournament.simple_tasks_title')
                },
                {
                    key: 'tournament',
                    label: this.$t('tournament.tournaments_title')
                },
                {
                    key: 'position',
                    label: this.$t('titles.position')
                },
                {
                    key: 'qty',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'start_date',
                    label: this.$t('titles.start_date')
                },
                {
                    key: 'end_date',
                    label: this.$t('titles.end_date')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        },
        calendarMinDate() {
            if (this.tournament.start_date) {
                return formatDateToYMD(this.tournament.start_date);
            }
            return null;
        },
        calendarMaxDate() {
            const { calendarMinDate } = this;
            return addMonths(calendarMinDate, 1) || null;
        }
    },

    async mounted() {
        this.requestPending = true;
        await this.getTournament();
        this.requestPending = false;
    },

    methods: {
        checkLocales,
        checkLang,
        async getTournament() {
            const body = {
                id: parseInt(this.$route.params.id)
            };
            await api.tournament
                .fetchOneTournament(body)
                .then((response) => {
                    this.tournament = response.data.result;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(async () => {
                    await this.getTournamentTasks();
                });
        },

        async getTournamentTasks() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                tournament_id: parseInt(this.$route.params.id),
                date: formatDateToYMD(this.dateTasks) || this.calendarMinDate
            };
            await api.tournament
                .fetchAllTournamentTasks(body)
                .then((response) => {
                    this.items = response.data.result;
                    this.sortItemsByPosition();
                    this.pagination = response.data.pagination;

                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createTournamentTask(body) {
            await api.tournament
                .createTournamentTask(body)
                .then(({ data }) => {
                    this.items.push(data);
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async updateTournamentTask(body, config) {
            await api.tournament
                .updateTournamentTask(body, config)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteTournamentTask(id) {
            const params = { id };
            await api.tournament
                .deleteTournamentTask(params)
                .then(() => {
                    this.getTournamentTasks();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        sortItemsByPosition() {
            this.items = sortItems(this.items, 'position');
        },

        async updatePosition({ moved }) {
            const body = {
                id: moved.element.id,
                position: ++moved.newIndex
            };

            await api.tournament
                .setPositionTournamentTask(body)
                .then(() => {
                    this.$toast.success(this.$t('success.updated.position'));
                })
                .catch(() => {
                    this.$toast.error(this.$t('error.smth'));
                })
                .finally(() => {
                    this.getTournamentTasks();
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = Object.assign({}, this.dataForModal);

                    body.task_id = this.dataForModal.task.id;
                    body.tournament_id = this.dataForModal.tournament.id;

                    delete body.end_date;
                    delete body.task;
                    delete body.tournament;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateTournamentTask(body, config);
                            await this.getTournamentTasks();
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createTournamentTask(body, config);
                            await this.getTournamentTasks();
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.$refs.createUpdateModal.reset();
                        this.isOpenModal = this.createOneMore;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    } finally {
                        this.dataForModal = {
                            task: '',
                            tournament: {},
                            start_date: null,
                            // end_date: null,
                            qty: null,
                            position: null
                        };
                        await this.getTournamentTasks();
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getTournamentTasks();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        openTaskModal(item) {
            this.openModalToUpdate(item);
        },

        beforeClose() {
            this.onCloseModal();
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>
                {{ $t('tournament.tasks_title') }} - {{ checkLocales(tournament.name) }}
            </template>

            <template #button>
                <router-link
                    :to="{ name: 'tournament-all-tasks', params: { id: $route.params.id } }"
                >
                    <b-button variant="primary" class="mr-1">
                        {{ $t('tournament.not_filtered') }}
                    </b-button>
                </router-link>

                <span style="display: flex; column-gap: 1rem">
                    <div
                        v-if="calendarMinDate"
                        class="d-flex align-items-center"
                        style="max-width: 250px"
                    >
                        <flat-pickr
                            v-model="dateTasks"
                            :config="{
                                minDate: calendarMinDate,
                                maxDate: calendarMaxDate,
                                defaultDate: calendarMinDate
                            }"
                            class="form-control flat-picker"
                            placeholder="YYYY-MM-DD"
                            autocomplete="off"
                        />
                    </div>

                    <b-button
                        v-if="hasAccess('tournament_tasks', 'create')"
                        v-b-modal.createUpdateModal
                        class="ml-auto"
                        variant="success"
                    >
                        {{ $t('button.add') }}
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </span>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <!--                <b-table-->
            <!--                v-else-->
            <!--                :fields="fields"-->
            <!--                :items="items"-->
            <!--                :responsive="true"-->
            <!--                borderless-->
            <!--                class="news__table"-->
            <!--                outlined-->
            <!--                striped-->
            <!--            >-->
            <!--                <template #cell(id)="{ item }">-->
            <!--                    {{ item.id }}-->
            <!--                </template>-->

            <!--                <template #cell(task)="{ item }">-->
            <!--                    <div v-if="item.task" style="min-width: 200px">-->
            <!--                        {{ checkLocales(item.task.name) }}-->
            <!--                    </div>-->
            <!--                </template>-->

            <!--                <template #cell(tournament)="{ item }">-->
            <!--                    <div v-if="item.tournament" style="min-width: 200px">-->
            <!--                        {{ checkLocales(item.tournament.name) }}-->
            <!--                    </div>-->
            <!--                </template>-->

            <!--                <template #cell(actions)="{ item, index }">-->
            <!--                    <div class="d-flex justify-content-end">-->
            <!--                        <table-action-btns-->
            <!--                            :index="index"-->
            <!--                            :item="item"-->
            <!--                            :update-access="{ section: 'product_categories', permission: 'update' }"-->
            <!--                            @openModal="openTaskModal"-->
            <!--                        />-->
            <!--                    </div>-->
            <!--                </template>-->
            <!--            </b-table>-->

            <div v-else class="informations-draggable-table">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                <div class="d-flex justify-content-center">#</div>
                            </th>
                            <th>
                                {{ $t('tournament.simple_tasks_title') }}
                            </th>
                            <!--                            <th>-->
                            <!--                                {{ $t('tournament.tournaments_title') }}-->
                            <!--                            </th>-->
                            <!--                            <th>-->
                            <!--                                {{ $t('titles.position') }}-->
                            <!--                            </th>-->
                            <th class="d-flex justify-content-center">
                                {{ $t('titles.qty') }}
                            </th>
                            <!--                            <th>-->
                            <!--                                {{ $t('titles.start_date') }}-->
                            <!--                            </th>-->
                            <!--                            <th>-->
                            <!--                                {{ $t('titles.end_date') }}-->
                            <!--                            </th>-->
                            <th></th>
                        </tr>
                    </thead>

                    <draggable
                        v-model="items"
                        handle=".handle"
                        tag="tbody"
                        @change="updatePosition"
                    >
                        <tr v-for="(item, index) in items" :key="item.index">
                            <td>
                                <div class="handle cursor-move">
                                    <feather-icon icon="AlignJustifyIcon" />
                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    {{ index + 1 }}
                                </div>
                            </td>
                            <td v-if="item.task && item.task.name">
                                <div class="d-flex flex-column ml-25" style="min-width: 100px">
                                    <b-card-text class="mb-0 font-weight-bold">
                                        {{ checkLang(item.task.name) }}
                                    </b-card-text>
                                </div>
                            </td>
                            <!--                            <td>-->
                            <!--                                <div class="d-flex flex-column ml-25">-->
                            <!--                                    <b-card-text class="mb-0 font-weight-bold">-->
                            <!--                                        {{ checkLocales(item.tournament.name) }}-->
                            <!--                                    </b-card-text>-->
                            <!--                                </div>-->
                            <!--                            </td>-->
                            <!--                            <td>-->
                            <!--                                <div class="d-flex flex-column align-items-center ml-25">-->
                            <!--                                    <b-card-text class="mb-0 font-weight-bold">-->
                            <!--                                        {{ checkLocales(item.position) }}-->
                            <!--                                    </b-card-text>-->
                            <!--                                </div>-->
                            <!--                            </td>-->
                            <td>
                                <div class="d-flex flex-column align-items-center ml-25">
                                    <b-card-text class="mb-0 font-weight-bold">
                                        {{ checkLocales(item.qty) }}
                                    </b-card-text>
                                </div>
                            </td>
                            <!--                            <td>-->
                            <!--                                <div class="d-flex flex-column ml-25">-->
                            <!--                                    <b-card-text class="mb-0 font-weight-bold">-->
                            <!--                                        {{ checkLocales(item.start_date) }}-->
                            <!--                                    </b-card-text>-->
                            <!--                                </div>-->
                            <!--                            </td>-->
                            <!--                            <td>-->
                            <!--                                <div class="d-flex flex-column ml-25">-->
                            <!--                                    <b-card-text class="mb-0 font-weight-bold">-->
                            <!--                                        {{ checkLocales(item.end_date) }}-->
                            <!--                                    </b-card-text>-->
                            <!--                                </div>-->
                            <!--                            </td>-->
                            <td>
                                <table-action-btns
                                    :index="index"
                                    :item="item"
                                    @openModal="openModalToUpdate"
                                />
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.tournament_tasks') : $t('add.tournament_tasks')"
            cancel-variant="danger"
            centered
            size="lg"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--     TOURNAMENT ID     -->
                    <b-col class="mb-1" cols="6">
                        <tournaments-select v-model="dataForModal.tournament" />
                    </b-col>

                    <!--     TASK ID     -->
                    <b-col class="mb-1" cols="6">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('tournament.simple_tasks_title')}`"
                            rules="required"
                        >
                            <tasks-select v-model="dataForModal.task" />
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    START DATE    -->
                    <b-col cols="6">
                        <label>{{ $t('titles.start_date') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.start_date')}`"
                            rules="required"
                        >
                            <!--                        <b-form-datepicker-->
                            <!--                            v-model="dataForModal.date"-->
                            <!--                            class="mb-1"-->
                            <!--                            style="z-index: 99"-->
                            <!--                        />-->
                            <div class="input-group">
                                <flat-pickr
                                    v-model="dataForModal.start_date"
                                    :config="{
                                        minDate: calendarMinDate,
                                        maxDate: calendarMaxDate,
                                        allowInput: true
                                    }"
                                    :placeholder="`${$t('choose.date')}`"
                                    class="mb-1 form-control"
                                    name="date"
                                />
                            </div>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    END DATE    -->
                    <!--                    <b-col cols="6">-->
                    <!--                        <label>{{ $t('titles.end_date') }}</label>-->
                    <!--                        &lt;!&ndash;                        <b-form-datepicker&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                            v-model="dataForModal.date"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                            class="mb-1"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                            style="z-index: 99"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                        />&ndash;&gt;-->
                    <!--                        <div class="input-group">-->
                    <!--                            <flat-pickr-->
                    <!--                                v-model="dataForModal.end_date"-->
                    <!--                                :config="{ enableTime: true, allowInput: true, time_24hr: true }"-->
                    <!--                                :placeholder="`${$t('choose.date')}`"-->
                    <!--                                class="mb-1 form-control"-->
                    <!--                                name="date"-->
                    <!--                            />-->
                    <!--                        </div>-->
                    <!--                    </b-col>-->

                    <!--    QTY    -->
                    <b-col cols="6">
                        <label> {{ $t('titles.qty') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.qty')}`"
                            rules="required|numeric"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.qty"
                                    :placeholder="`${$t('enter.qty')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    POSITION    -->
                    <!--                    <b-col cols="6">-->
                    <!--                        <label> {{ $t('titles.position') }}</label>-->
                    <!--                        <ValidationProvider-->
                    <!--                            v-slot="{ errors }"-->
                    <!--                            :name="`${$t('titles.position')}`"-->
                    <!--                            rules="required|numeric"-->
                    <!--                        >-->
                    <!--                            <b-form-group>-->
                    <!--                                <b-form-input-->
                    <!--                                    v-model="dataForModal.position"-->
                    <!--                                    :placeholder="`${$t('enter.position')}`"-->
                    <!--                                    :state="errors.length > 0 ? false : null"-->
                    <!--                                    size="md"-->
                    <!--                                />-->
                    <!--                            </b-form-group>-->
                    <!--                            <span class="validation__red">-->
                    <!--                                {{ errors[0] }}-->
                    <!--                            </span>-->
                    <!--                        </ValidationProvider>-->
                    <!--                    </b-col>-->
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                    {{ $t('titles.add_more') }}
                </b-form-checkbox>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.table-img {
    width: 60px;
    height: 60px;
    background-color: #999999;
    border-radius: 0.25rem;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
    }
}

.text-limit {
    min-width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
